<template>
  <div id="benefits">
    <v-container class="content">
      <h2 class="display-2 mb-10 font-weight-bold primary--text">
        Benefits for
      </h2>
    </v-container>
    <div class="business">
      <v-container class="content">
        <p class="py-4 display-1 font-weight-bold">
          <span class="header-line-left primary--text">
            Businesses
          </span>
        </p>
        <v-row>
          <v-col cols="12" md="6">
            <v-list color="primary--text">
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Comprehensive Ecommerce solution supporting sales, collections
                  and deliveries
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Catalysing impulses purchases for and from business neighbours
                  – just like a high street!
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Unlimited number of products and orders
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Comprehensive back end app for traders including product
                  management, route planning, collection and delivery slot logic
                  and order management
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Low cost – with BID financial support and economies of scale
                  the platform is very affordable
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Technical support in set up and ongoing – including security
                  and upgrades of the platform and all running and hosting costs
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <div class="full-height full-width img-left-skew">
        <v-img
          aspect-ratio="591/400"
          class="flex-grow-0"
          position="50% center"
          height="100%"
          width="50%"
          :src="require('@/assets/benefit_business.jpeg')"
        />
      </div>
    </div>
    <v-sheet class="py-12" color="grey lighten-2"></v-sheet>
    <div class="bids_and_councils">
      <v-container class="content bids_and_councils">
        <p
          class="py-4 display-1 font-weight-bold benefit-label"
          :class="{
            'text-left': $vuetify.breakpoint.smOnly,
            'text-right': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <span
            class="header-line-right pa-2 pl-4 d-inline-block primary--text"
            :class="{ white: $vuetify.breakpoint.mdAndUp }"
          >
            BIDS, Councils and Town Centre Partnerships
          </span>
        </p>
        <v-row>
          <v-col cols="12" md="6" offset-md="6">
            <v-list>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  A modern digital presence for all town centres and high
                  streets providing much needed immediate support for high
                  streets and town centres during uniquely challenging times
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Supports the four ‘pillars’ of high street and town centre
                  resilience – economic, social, financial and environmental
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Firmly on ‘trend’ with clear signs of a customer move in
                  favour of internet shopping
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Future upgrades enabling a virtuous circle between local
                  residents, businesses and the wider community
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Flexible and low cost solution - a permanent everyday low
                  price, with no initial charges in the first 2 months and
                  ability to decide own domain, brand, boundaries, sectors etc –
                  ie make this the town’s own
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-circle-medium</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  Ease of use – the platform requires almost no local support
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <div class="full-height full-width img-right-skew">
        <v-img
          aspect-ratio="591/400"
          class="flex-grow-0"
          position="center"
          height="100%"
          width="50%"
          :src="require('@/assets/benefit_bids_councils.jpeg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefits",
};
</script>

<style lang="scss" scoped>
.business,
.bids_and_councils {
  position: relative;
}

.benefit-label {
  position: relative;
  z-index: 1;
}
</style>
